.h-50vh{
    height: 50vh;
}
.h-37vh{
    height: 37vh;
}

.h-0{
    height: 0;
    overflow: hidden;
}

.p-5{
    padding: 1.25rem;
}

.p-0{
    padding: 0;
}

.top-540px{
    top: 540px;
}

.b-t-l-r-15{
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

.h-57vh{
    height: 57vh;
}


/*#homepage ul li:nth-last-child(-n+2) {*/
/*    width: 50% !important;*/
/*}*/
#homepage ul li:nth-last-child(-n+3) img:last-child {
    display: none;
}
#homepage ul li:nth-last-child(-n+3) a {
    display: flex;
    flex-flow: wrap;
    align-items: center;
    justify-content: center;
}