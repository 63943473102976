#menugenerale .h-50vh{
    height: 50vh;
}
#menugenerale .h-37vh{
    height: 37vh;
}

#menugenerale .h-0{
    height: 0;
    overflow: hidden;
}

#menugenerale .p-5{
    padding: 1.25rem;
}

#menugenerale .p-0{
    padding: 0;
}

#menugenerale .top-540px{
    top: 540px;
}

#menugenerale .b-t-l-r-15{
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

#menugenerale .h-57vh{
    height: 57vh;
}


/*#homepage ul li:nth-last-child(-n+2) {*/
/*    width: 50% !important;*/
/*}*/
#homepage #menugenerale ul li:nth-last-child(-n+3) img:last-child {
    display: flex!important;
    flex-flow: wrap!important;
    align-items: center!important;
    justify-content: space-between!important;
}
#homepage #menugenerale ul li:nth-last-child(-n+3) a {
    display: flex!important;
    flex-flow: wrap!important;
    align-items: center!important;
    justify-content: space-between!important;
}