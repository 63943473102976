@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
  font-family: 'Nunito';
  src: url('./assets/fonts/Nunito-Bold.eot');
  src: local('Nunito Bold'), local('Nunito-Bold'),
  url('./assets/fonts/Nunito-Bold.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/Nunito-Bold.woff2') format('woff2'),
  url('./assets/fonts/Nunito-Bold.woff') format('woff'),
  url('./assets/fonts/Nunito-Bold.ttf') format('truetype'),
  url('./assets/fonts/Nunito-Bold.svg#Nunito-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

body {
  margin: 0;
  /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',*/
  /*  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',*/
  /*  sans-serif;*/
  font-family: 'Nunito', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#GoBackButton{
  display: none!important;
}